
import { menuController } from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import moment from "moment";

export default defineComponent({
  name: "SignInFilterMenu",
  emits: ["on-close"],
  setup(props, context) {
    const signIn_type = reactive({
      data: [
        { name: "全部", value: 0 },
        { name: "二维码", value: 1 },
        { name: "外出签到", value: 2 },
        { name: "APP签到", value: 3 },
      ],
    });

    const onClose = () => {
      menuController.close();
    };

    let filter_name = ref("");
    let filter_type = ref(0);
    let filter_date_start = ref("");
    let filter_date_end = ref("");

    const keyNameHandler = (ev: any) => {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        confirm();
      }
    };

    const checkType = (val: any) => {
      filter_type.value = val.value;
    };

    const resetTime = (val: any) => {
      if (val == 0) {
        filter_date_start.value = "";
      }
      if (val == 1) {
        filter_date_end.value = "";
      }
    };

    const reset = () => {
      filter_name.value = "";
      filter_type.value = 0;
      filter_date_start.value = "";
      filter_date_end.value = "";
    };

    const confirm = () => {
      if (filter_date_start.value != "") {
        filter_date_start.value = moment(filter_date_start.value).format("YYYY-MM-DD");
      }
      if (filter_date_end.value != "") {
        filter_date_end.value = moment(filter_date_end.value).format("YYYY-MM-DD");
      }
      const params = {
        name: filter_name.value,
        type: filter_type.value,
        dateStart: filter_date_start.value,
        dateEnd: filter_date_end.value,
      };
      context.emit("on-close", params);
    };

    return {
      signIn_type,
      filter_name,
      filter_type,
      filter_date_start,
      filter_date_end,
      onClose,
      checkType,
      resetTime,
      keyNameHandler,
      reset,
      confirm,
    };
  },
});
