
import { defineComponent, onMounted, reactive, ref } from "vue";
import { RecordService } from "@/services/attendance/record/RecordService";
import SystemUtils from "@/utils/SystemUtils";
import { useRouter } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "SignInList",
  setup() {
    const signInList = reactive({
      data: new Array(),
    });

    let page = 1;
    let size = 10;

    const service = new RecordService();

    const user = SystemUtils.loginUser;

    const fillZero = (num: number) => {
      if (num <= 9) {
        return "0" + num;
      }
      return num;
    };

    let startDate = "";
    let endDate = "";

    const isScroll = ref(false);

    const loadList = () => {
      const params = {
        userId: user.id,
        page: page,
        size: size,
        startDate: startDate,
        endDate: endDate,
      };
      service
        .getAllList(params)
        .then(
          (result: any) => {
            if (result) {
              if (result.rows.length > 0) {
                if (page == 1) {
                  signInList.data = result.rows;
                } else {
                  signInList.data = signInList.data.concat(result.rows);
                }
              } else {
                isScroll.value = true;
              }
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const router = useRouter();

    const goInto = (id: string) => {
      router.push({
        name: "SignInDetail",
        params: {
          id: id,
        },
      });
    };

    const initDate = () => {
      page = 1;
      loadList();
    };

    const bindChangeStartDate = (ev: any) => {
      startDate = moment(ev.detail.value).format("YYYY-MM-DD");
      initDate();
    };

    const bindChangeEndDate = (ev: any) => {
      endDate = moment(ev.detail.value).format("YYYY-MM-DD");
      initDate();
    };

    const loadData = (ev: any) => {
      setTimeout(() => {
        page += 1;
        loadList();
        ev.target.complete();
        if (signInList.data.length == 1000) {
          ev.target.disabled = true;
        }
      }, 500);
    };

    const startDate_def = ref("");
    const endDate_def = ref("");
    onMounted(() => {
      const currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth() + 1;
      startDate = startDate_def.value =
        currentYear + "-" + fillZero(currentMonth) + "-01";
      endDate = endDate_def.value =
        currentYear +
        "-" +
        fillZero(currentMonth) +
        "-" +
        fillZero(currentDate.getDate());
      initDate();
    });

    return {
      signInList,
      goInto,
      initDate,
      bindChangeStartDate,
      bindChangeEndDate,
      loadData,
      isScroll,
      startDate_def,
      endDate_def,
    };
  },
});
