
import { defineComponent, reactive, ref } from "vue";
import Header from "@/components/Header.vue";
import { menuController } from "@ionic/vue";
import SignInFilterMenu from "@/views/attendance/signIn/filter/FilterMenu.vue";
import SignInList from "@/views/attendance/signIn/list/SignInList.vue";
import OutSignInList from "@/views/attendance/signIn/outList/OutSignInList.vue";

export default defineComponent({
  name: "FilterSignIn",
  components: {
    Header,
    SignInFilterMenu,
    SignInList,
    OutSignInList,
  },
  setup() {
    const btnSizeRight = reactive({ data: [] }); //"38px"
    const btnIconRight = reactive({ data: [] }); //"iconshaixuan"

    const selTb = ref(0);

    const chips = reactive({
      data: ["考勤签到", "外出签到"],
    });

    const listTab = reactive({
      data: ["SignInList", "OutSignInList"],
    });

    const getSignInList = () => {
      return listTab.data[selTb.value];
    };

    const openMenuModal = (r: any) => {
      if (r == "0_icon") {
        menuController.enable(true, "SignInFilterMenu");
        menuController.open("SignInFilterMenu");
      }
    };

    const filterHandler = (r: any) => {
      menuController.close();
    };

    const segmentChanged = (val: number) => {
      selTb.value = val;
    };

    return {
      btnSizeRight,
      btnIconRight,
      selTb,
      chips,
      openMenuModal,
      filterHandler,
      segmentChanged,
      getSignInList,
    };
  },
});
